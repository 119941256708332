import lscache from 'lscache';

import { isServerSide } from 'Common/utils/serverSide';
import { TokensStore } from '../behaviors/tokens/types';

// time in minutes. default 24 hours to match that of the consumer token.
const DEFAULT_EXPIRATION = 60 * 24;

export const PERSISTED_TOKENS = {
  // there are 5 recognized UTM parameters
  // https://en.wikipedia.org/wiki/UTM_parameters
  utmSource: {
    queryParam: 'utm_source'
  },
  utmMedium: {
    queryParam: 'utm_medium'
  },
  utmCampaign: {
    queryParam: 'utm_campaign'
  },
  utmTerm: {
    queryParam: 'utm_term'
  },
  utmContent: {
    queryParam: 'utm_content'
  },
  bcsToken: {
    queryParam: 'bcs_token'
  }
} as Record<
  keyof TokensStore,
  {
    queryParam: string;
  }
>;

// exported for testing only. We use a function here to be able to mock it out
export function _getAllTokens(): Record<string, { queryParam: string }> {
  return PERSISTED_TOKENS;
}

/**
 * function for managing persisted tokens off of query
 * If a token is found in the query params, it will be stored in local storage
 * and refreshed upon subsequent page views until the expiration time.
 *
 * The function only works client-side because localstorage is not available on the server
 */
export function loadPersistedTokensIntoStorage(): Record<string, string> {
  // tokens that might appear as GET params on a url

  if (isServerSide()) {
    // this function should never be called on the server, since local storage doesn't exist there,
    // but check it here, just in case, to avoid breaking the request handler
    // eslint-disable-next-line no-console -- shouldn't ever happen, and we don't have access to log, so use console
    console.error(
      'loadPersistedTokensIntoStorage should not be called on the server'
    );
    return {};
  }

  let queryParams: URLSearchParams | null;
  try {
    // window could be undefined on server, or we could have a totally invalid query
    queryParams = new URLSearchParams(window.location.search);
  } catch (e) {
    queryParams = null;
  }

  const tokenValues: Record<string, string | null> = Object.entries(
    _getAllTokens()
  ).reduce((acc, [name, { queryParam }]) => {
    const value =
      _getFromQuery(queryParam, queryParams) || _getFromLocalStorage(name);
    return { ...acc, [name]: value };
  }, {});

  const presentTokens: Record<string, string> = {};

  // set the tokens in local storage
  Object.entries(tokenValues).forEach(([name, value]) => {
    if (value) {
      presentTokens[name] = value;
      lscache.set(name, value, DEFAULT_EXPIRATION);
    }
  });

  return presentTokens;
}

export function getPersistedTokenValue(
  tokenName: keyof TokensStore
): string | null {
  const tokenRecord = PERSISTED_TOKENS[tokenName];
  if (!tokenRecord) {
    return null;
  }
  const queryParams = new URLSearchParams(window.location.search);
  return (
    _getFromQuery(tokenRecord.queryParam, queryParams) ||
    _getFromLocalStorage(tokenName)
  );
}

export function getAllPersistedTokenValues(): string[][] {
  const tokenNames = Object.values(PERSISTED_TOKENS).map(
    (token) => token.queryParam
  );
  if (tokenNames.length <= 0) {
    return [];
  }
  const queryParams = new URLSearchParams(window.location.search);
  const presentTokens = [];
  for (const tokenName of tokenNames) {
    const value =
      _getFromQuery(tokenName, queryParams) || _getFromLocalStorage(tokenName);
    if (value) {
      presentTokens.push([tokenName, value]);
    }
  }
  return presentTokens;
}

// export for testing only
export function _getFromQuery(
  queryParam: string,
  queryParams: URLSearchParams | null
): string | null {
  if (queryParams) {
    return queryParams.get(queryParam);
  }
  return null;
}

// export for testing only
export function _getFromLocalStorage(key: string): string | null {
  return lscache.get(key);
}
