import {
  AnalyticsProviderReport,
  AnalyticsProvider
} from './analytics-provider';
import trackyr from '@kyruus/trackyr';

export interface TrackyrMetadata {
  product_name: string;
  tracking_token: string;
  deployment: string;
  customer_code: string;
  user_id: string;
  bcs_token: string;
  utm_source: string;
  utm_medium: string;
  referrer: string;
}

export interface TrackyrInitMetadata {
  customer_code: string;
  deployment: string;
  product_name: string;
  referrer: string;
  tracking_token: string;
  user_id: string;
}

export interface TrackyrAnalyticsProviderOptions {
  eventMetdata: TrackyrMetadata;
  trackyrMetadata: TrackyrInitMetadata;
}

export class TrackyrAnalyticsProvider implements AnalyticsProvider {
  public providerName = 'muus';

  constructor(private options: TrackyrAnalyticsProviderOptions) {
    trackyr.init(this.options.trackyrMetadata);
  }

  public sendEvent(
    eventName: string,
    eventData: any
  ): AnalyticsProviderReport | null {
    const eventPayload = { ...eventData, ...this.options.eventMetdata };

    trackyr.track(eventName, eventPayload);

    return {
      eventName,
      eventData: { ...eventPayload, ...this.options.trackyrMetadata }
    };
  }
}
