import { events } from './constants';

// white list events that take the user to DBW
const TEALIUM_EVENT_WHITELIST = [
  'user_action.search_results.view_search_results',
  'user_action.search_results.provider_card.availability_tiles.view_all_appointments',
  'user_action.search_results.profile.availability_tiles.view_all_appointments',
  'user_action.alter_view.request_appointment',
  'user_action.alter_view.request_appointment.phone',
  'user_action.provider_timeslot_selected_search',
  'user_action.provider_timeslot_selected_provider_profile',
  'user_action.existing_patient_view.select_patient_type',
  'user_action.pmcdb.booking.success',
  'user_action.provider_detail.new_search'
];

/**
 * return whether or not the given eventName should track to tealium.
 * We only want page views and certain whitelisted events to be tracked.
 * @param {*} eventName
 */
export function shouldTrackToTealium(eventName) {
  if (eventName.indexOf(events.USER_ACTION_PAGE_VIEW) !== -1) {
    return true;
  }

  if (TEALIUM_EVENT_WHITELIST.includes(eventName)) {
    return true;
  }

  return false;
}

export const trackTealiumEvent = (eventName, eventData = {}) => {
  if (window.utag) {
    // for page views, use utag.view utag_data should automatically
    // be appended by the utag script
    if (eventName.indexOf(events.USER_ACTION_PAGE_VIEW) !== -1) {
      const trackingData = {
        tealium_event: eventName,
        event_name: eventName,
        ...eventData
      };
      window.utag.view({ ...trackingData });
    } else {
      // for non-pageviews, use utag.link. utag_data should automatically
      // be appended by the utag script
      const trackingData = {
        tealium_event: eventName,
        event_name: eventName,
        ...eventData
      };
      window.utag.link(trackingData);
    }
  }
};
