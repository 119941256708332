import {
  AnalyticsProviderReport,
  AnalyticsProvider
} from './analytics-provider';

declare global {
  interface Window {
    // testcafe functional testing
    '%hammerhead%': boolean;
  }
}

export interface IdentityMetadata {
  customer_id: string;
  actor: string;
  distinct_id: string;
  email: string;
  user_type: string;
}

export interface HammerheadAnalyticsProviderOptions {
  metadata: IdentityMetadata;
}

export class HammerheadAnalyticsProvider implements AnalyticsProvider {
  public providerName = 'hammerhead';

  constructor(private options: HammerheadAnalyticsProviderOptions) {}

  public sendEvent(
    eventName: string,
    eventData: any
  ): AnalyticsProviderReport | null {
    // hammerhead exists when testcafe runs
    // https://stackoverflow.com/a/55451665
    if (window && !window['%hammerhead%']) {
      return null;
    }

    const eventPayload = {
      eventName,
      eventData: {
        ...eventData,
        ...this.options.metadata
      }
    };

    // NOTE: THIS `console.log` call IS used for `functional tests`
    // eslint-disable-next-line no-console
    console.log(
      JSON.stringify({
        type: 'tracking',
        data: eventPayload
      })
    );

    return { ...eventPayload };
  }
}
