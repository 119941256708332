import { shouldTrackToTealium, trackTealiumEvent } from 'Src/tracking';
import {
  AnalyticsProviderReport,
  AnalyticsProvider
} from './analytics-provider';

export interface TealiumMetadata {
  // todo: these may not be correct, generated by ai.
  product_name: string;
  tracking_token: string;
  deployment: string;
  customer_code: string;
  user_id: string;
  bcs_token: string;
  utm_source: string;
  utm_medium: string;
  referrer: string;
}

export interface TealiumAnalyticsProviderOptions {
  metadata: TealiumMetadata;
}

export class TealiumAnalyticsProvider implements AnalyticsProvider {
  public providerName = 'tealium';

  constructor(private options: TealiumAnalyticsProviderOptions) {}

  public sendEvent(
    eventName: string,
    eventData: any
  ): AnalyticsProviderReport | null {
    if (!shouldTrackToTealium(eventName)) {
      return null;
    }

    const eventPayload = { ...eventData, ...this.options.metadata };

    trackTealiumEvent(eventName, eventPayload);

    return {
      eventName,
      eventData: eventPayload
    };
  }
}
