import _kebabCase from 'lodash/kebabCase';
import _isEmpty from 'lodash/isEmpty';
import querystring from 'querystring';

import { MODULES, isModuleEnabled } from 'Common/config';
import { getLocationUrl, getLocationPath } from 'Common/urls/location';

/**
 * NOTE: This is for the /location page - Location Profile Page (active_modules: "locations")
 * Returns true if "locations" is enabled in the config, else false
 * @param {object} config the PMC config from CS
 * @returns {boolean}
 */
export const locationsModuleEnabled = (config) => {
  return isModuleEnabled(config, MODULES.LOCATIONS);
};

/**
 * Returns true if the location has an mloc ID, else false
 * @param {object} location the location object
 * @returns {boolean}
 */
export const isMarketableLocation = (location) => {
  return Boolean(location.primary_marketable_location_id);
};

/**
 * Returns the mloc location URL (string) for use in links' hrefs if it can, else null.
 * NOTE: This function is passed to ProviderTile, which relies on it returning null when
 * locations is turned off or if the supplied location is not an mloc (impacts whether it
 * renders links or just location names on the card)
 * @param {boolean} locationsModuleEnabled whether or not locations are enabled in the PMC config
 * @param {object} location the location object
 * @param {string} queryString the current query string in the URL (must include the ?)
 * @returns {string} if locations enabled and location is mloc
 * @returns {null} otherwise
 */
export const buildLocationURL = (
  locationsModuleEnabled,
  location,
  queryString
) => {
  if (locationsModuleEnabled && isMarketableLocation(location)) {
    const locationName = _kebabCase(location.name);
    return `${getLocationPath(
      locationName,
      location.primary_marketable_location_id
    )}${queryString}`;
  } else {
    return null;
  }
};

/**
 * Returns the canonical URL for the link tag on the MLOC page
 * @param {string} host the host from the config (added in the proxy request pipeline)
 * @param {object} location the location object that comes back from location service on the MLOC page
 * @returns
 */
export const buildLocationCanonicalURL = (host, location) => {
  // the location objects that come back from location service have _entity_id, not primary_marketable_location_id
  // primary_marketable_location_id comes back in the 'locations' object for provider results on SERP (v9)
  // the location results from the locations endpoint for v9 brings back 'id' though, not _entity_id...
  return getLocationUrl(host, location.name, location._entity_id);
};

/**
 * Geocode a location -- get lat/lon from a string -- using AWS Location Service Places Index
 */
export async function fetchGeocodeSuggestions({ address }) {
  const awsGeocodeRes = await fetch(`/aws-maps/geocode/${address}`);
  const { Results: results } = await awsGeocodeRes.json();
  return results;
}

const LAT_LON_REGEX = /^-?[0-9]+\.[0-9]+,-?[0-9]+\.[0-9]+$/;

// given a searchLocation, resolve it to lat/lon coords if it is not
// already in that format. Utilizes the google locations api.
// This function can throw an exception and needs to be handled by an consumers
export async function getResolvedSearchLocation(searchLocation) {
  let resolvedSearchLocation = searchLocation;
  // geocode the location if it's not already a lat/lon string
  if (!LAT_LON_REGEX.test(resolvedSearchLocation)) {
    const results = await fetchGeocodeSuggestions({
      address: resolvedSearchLocation
    });
    const locationGeo = results[0].Place.Geometry.Point;
    resolvedSearchLocation = `${locationGeo[1]},${locationGeo[0]}`;
  }
  return resolvedSearchLocation;
}

/**
 * Gets a stringified display address string
 * @param {object} address The location to get the display address for.
 * @return {string} Display friendly address string
 */
export const getDisplayAddress = (address) => {
  const street2 = address.street2 ? address.street2 + ', ' : '';
  const suite = address.suite ? address.suite + ', ' : '';

  return `${address.street1}, ${street2}${suite}${address.city}, ${address.state} ${address.zip}`;
};

/**
 * Gets the hero image for a location
 * @param {object[]} media
 * @return {string | undefined} The hero image URL
 */
export const getHeroImage = (media = []) =>
  media.find(
    ({ label, media_type }) =>
      media_type === 'image' && label?.toLowerCase() === 'hero'
  )?.url;

export const getPhone = (contacts) => {
  const phone = contacts.find((item) => item.contact_type === 'phone');
  return phone && phone.value;
};

export const getFax = (contacts) => {
  const fax = contacts.find((item) => item.contact_type === 'fax');
  return fax && fax.value;
};

export const getEmail = (contacts) => {
  const email = contacts.find((item) => item.contact_type === 'email');
  return email && email.value;
};

export const getWebsiteUrl = (urls) => {
  const url = urls.find((url) => url.primary);
  const href = url && url.value;
  if (href) {
    const loc = new URL(url.value);
    return {
      href,
      display: loc.hostname.replace('www.', '')
    };
  }
};

export const getBackToSearchUrl = (currentQuery) => {
  let backToSearchResults = null;
  if (!_isEmpty(currentQuery)) {
    backToSearchResults = `/search?${querystring.stringify(currentQuery)}`;
  }
  return backToSearchResults;
};
