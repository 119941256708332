import type { LoggingMetadata } from 'Common/types/logging';
import {
  getCommonTrackingParams,
  METADATA_STORE_NAME
} from 'Common/logging/tokens';

import { CONSUMER_TRACKING_TOKEN, getTokenValue } from 'Common/logging/tokens';

import { getExternalTrackingParams } from './tracking-utils';

import {
  analyticsService,
  GoogleAnalyticsProvider,
  HammerheadAnalyticsProvider,
  TrackyrAnalyticsProvider,
  TealiumAnalyticsProvider
} from 'Src/analytics';
import {
  isAdminEnabled,
  isHammerheadEnvironment,
  sendAdminAnalyticsReport,
  shouldDebugAnalyticsProviders
} from './utilities';
import { DatadogAnalyticsProvider } from 'Src/analytics/providers/datadog';

declare global {
  interface Window {
    _admin_logging: any;
    admin: any;
    [METADATA_STORE_NAME]: LoggingMetadata;
  }
}

const PRODUCT_NAME = 'Providermatch Client';

/**
 * @typedef {Object} TrackingInitConfig
 * @prop {Object} initMetadata
 * @prop {Object} eventMetadata
 */

export function getInitAndEventMetadata({
  loggingMetadata
}: {
  loggingMetadata: LoggingMetadata;
}): { eventMetadata: any; initMetadata: any } {
  const initMetadata = {
    product_name: PRODUCT_NAME,
    tracking_token: getTokenValue({ token: CONSUMER_TRACKING_TOKEN }),
    deployment: loggingMetadata.deployment || '',
    customer_code: loggingMetadata.customerCode || '',
    user_id: loggingMetadata.userId || ''
  };

  const eventMetadata = {
    customer_id: loggingMetadata.customerCode || '',
    actor: loggingMetadata.actor || '',
    distinct_id: loggingMetadata.distinctId || '',
    email: loggingMetadata.email || '',
    user_type: loggingMetadata.userType || ''
  };

  return { initMetadata, eventMetadata };
}

/**
 * initializes `trackyr` and returns a `log` function to be used for tracking
 * //
 * @param {{ loggingMetadata: TrackingInitConfig }} init_metadata
 * @returns {Function}
 **/
export const initializeLogging = ({
  loggingMetadata,
  customerConfig
}: {
  loggingMetadata: LoggingMetadata;
  customerConfig: any;
}) => {
  // set global logging metadata, for getTokenValue to be able to access
  // certain special page-wide tokens in a read only fashion.
  window[METADATA_STORE_NAME] = Object.freeze(
    loggingMetadata
  ) as LoggingMetadata;

  const {
    initMetadata: trackyrApplicationMetadata,
    eventMetadata: identityMetadata
  } = getInitAndEventMetadata({
    loggingMetadata
  });

  // collect external tracking params like those that google appends to tracked links
  const externalTrackingParams = getExternalTrackingParams();

  const trackyrInitMetadata = {
    ...trackyrApplicationMetadata,
    ...externalTrackingParams,
    referrer: window.document.referrer || 'UNKNOWN'
  };

  analyticsService.debug = shouldDebugAnalyticsProviders();

  if (isHammerheadEnvironment()) {
    analyticsService.addProvider(
      new HammerheadAnalyticsProvider({
        metadata: identityMetadata
      })
    );
  } else {
    analyticsService.addProvider(
      new TrackyrAnalyticsProvider({
        trackyrMetadata: trackyrInitMetadata,
        eventMetdata: { ...identityMetadata }
      })
    );

    analyticsService.addProvider(new GoogleAnalyticsProvider());

    if (customerConfig.tealium?.enabled ?? false) {
      analyticsService.addProvider(
        new TealiumAnalyticsProvider({
          metadata: { ...externalTrackingParams, ...identityMetadata }
        })
      );
    }

    if (customerConfig.datadog?.rum_enabled ?? false) {
      analyticsService.addProvider(
        new DatadogAnalyticsProvider({
          metadata: { ...identityMetadata }
        })
      );
    }
  }

  const log = (eventName: string, eventData = {}) => {
    try {
      // token values are re-calculated on every tracking call because they could
      // change during the session after the initial pageload
      const commonTrackingParams = getCommonTrackingParams();

      const event = isHammerheadEnvironment()
        ? { ...eventData }
        : { ...commonTrackingParams, ...eventData };

      const report = analyticsService.sendEvent(eventName, event);

      if (isAdminEnabled()) {
        sendAdminAnalyticsReport(eventName, report);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error tracking event', error);
    }
  };

  return { log };
};
