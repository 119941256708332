import { datadogRum } from '@datadog/browser-rum';
import {
  AnalyticsProvider,
  AnalyticsProviderReport
} from './analytics-provider';

export interface DatadogAnalyticsProviderOptions {
  metadata: any;
}

export class DatadogAnalyticsProvider implements AnalyticsProvider {
  public readonly providerName = 'datadog';

  constructor(private options: DatadogAnalyticsProviderOptions) {}

  public sendEvent(
    eventName: string,
    eventData: any
  ): AnalyticsProviderReport | null {
    const eventPayload = {
      ...eventData,
      ...this.options.metadata
    };

    datadogRum.addAction(eventName, eventPayload);

    return {
      eventName,
      eventData: eventPayload
    };
  }
}
