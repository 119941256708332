import _get from 'lodash/get';
import _set from 'lodash/set';
import _pickBy from 'lodash/pickBy';
import _defaults from 'lodash/defaults';
import _isEmpty from 'lodash/isEmpty';
import { PRODUCT_NAME_CONSUMER } from '../logging/constants';
import {
  DEFAULT_LOCALE,
  MODULES,
  PERSONAS,
  CONFIG_ERROR,
  DATADOG
} from './constants';
import { deepFreeze } from '../utils';

/**
 * Removes keys not found in the whitelist from config object from Customer Service.
 * @param config config object from Customer Service
 * @param whitelist the fields to be whitelisted
 * @return transformed config object
 */
export const whitelistConfigProperties = (config, whitelist = []) => {
  const unsupportedKeys = [];
  const results =
    whitelist.length > 0
      ? _pickBy(config, (_, key) => {
          const hasKey = ['errors'].includes(key) || whitelist.includes(key);
          if (!hasKey) {
            unsupportedKeys.push(key);
          }
          return hasKey;
        })
      : config;
  return {
    ...results,
    errors: [
      ...(results.errors ?? []),
      ...(unsupportedKeys.length > 0
        ? [{ type: CONFIG_ERROR.UNSUPPORTED_KEY, data: unsupportedKeys }]
        : [])
    ]
  };
};

/**
 * Normalizes the `active_modules` field of a Customer Service config object.
 * Converts array-based notation to object-based notation and sets defaults.
 * @param csConfig config object from Customer Service
 * @param defaultCsConfig default config object
 * @return config object with `active_modules` object
 */
export const normalizeActiveModules = (csConfig, defaultCsConfig) => {
  let { active_modules } = csConfig;

  // Set the default active modules if not set
  active_modules = _isEmpty(active_modules)
    ? defaultCsConfig.active_modules
    : active_modules;

  // Transform from array notation to object notation
  if (Array.isArray(active_modules)) {
    active_modules = active_modules.reduce((prev, curr) => {
      if (curr) {
        prev[curr] = { enabled: true };
      }
      return prev;
    }, {});
  }

  // For the 'consumer' persona (default persona) the profile module is enabled if:
  // * the profile module is explicitly enabled
  // or
  // * the profile module is not explicitly disabled
  // and
  // * the search_match module is enabled.
  //
  // For other personas, it's only enabled if:
  // * the profile module is explicitly set.
  const persona = getConfigProperty(csConfig, 'persona') ?? PERSONAS.CONSUMER;
  const profileEnabled = getConfigProperty(active_modules, 'profile.enabled');

  if (profileEnabled == null && persona === PERSONAS.CONSUMER) {
    active_modules = {
      ...active_modules,
      profile: {
        enabled:
          getConfigProperty(active_modules, 'search_match.enabled') === true
      }
    };
  }
  const unsupportedModules = [];
  const results = _pickBy(active_modules, (_, key) => {
    const hasKey = Object.values(MODULES).includes(key);
    if (!hasKey) {
      unsupportedModules.push(key);
    }
    return hasKey;
  });
  return {
    ...csConfig,
    active_modules: results,
    errors: [
      ...(csConfig.errors ?? []),
      ...(unsupportedModules.length > 0
        ? [{ type: CONFIG_ERROR.UNSUPPORTED_MODULE, data: unsupportedModules }]
        : [])
    ]
  };
};

/**
 * Sets default values for a Customer Service config object.
 * @param csConfig config object from Customer Service
 * @param defaultCsConfig default config object
 * @return config object with defaults set
 */
export const setDefaults = (csConfig, defaultCsConfig) => {
  // shallow copy the object in each call to _defaults to avoid mutating the base csConfig bject
  csConfig = _defaults({ ...csConfig }, defaultCsConfig);
  csConfig.feature_flags = _defaults(
    { ...csConfig.feature_flags },
    defaultCsConfig.feature_flags
  );
  csConfig.darkship_feature_flags = _defaults(
    { ...csConfig.darkship_feature_flags },
    defaultCsConfig.darkship_feature_flags
  );
  csConfig.datadog = _defaults(
    { ...csConfig.datadog },
    defaultCsConfig.datadog
  );

  return csConfig;
};

/**
 * Retrieves the default values for a Customer Service config object by persona.
 * @param persona persona whose config is to be fetched
 * @return default config object
 */
const getDefaultConfig = (persona) => {
  const nonConsumerPersonas = Object.values(PERSONAS).filter(
    (p) => p !== PERSONAS.CONSUMER
  );
  if (nonConsumerPersonas.includes(persona)) {
    return defaultConfigNonConsumer;
  }
  return defaultConfig;
};

/**
 * Normalizes a Customer Service config object.
 * Converts fields and sets defaults.
 * @param csConfig config object from Customer Service
 * @return normalized config object with defaults
 */
export const normalizeConfig = (csConfig) => {
  const defaultCsConfig = getDefaultConfig(
    getConfigProperty(csConfig, 'persona') ?? PERSONAS.CONSUMER
  );
  let config = normalizeActiveModules(csConfig, defaultCsConfig);
  config = setDefaults(config, defaultCsConfig);
  return config;
};

/**
 * Safely retrieves the property value at path of `customerConfig`.
 * @param {object} customerConfig The customer config from Customer Service.
 * @param {string} path The path of the property to get.
 * @param {any} [defaultValue] Optional value returned if the resolved value is `undefined`.
 * @return Returns the resolved value.
 *
 * @example
 * ```js
 * // Access properties via dot notation
 * getConfigProperty(customerConfig, 'active_modules.search_match.enabled');
 * ```
 */
export function getConfigProperty(
  customerConfig,
  path,
  defaultValue = undefined
) {
  return _get(customerConfig, path, defaultValue);
}

/**
 * Safely sets the property value at path of `config` object.
 * If the path does not exist, it creates all intermediate values.
 * @param customerConfig — The customer config from Customer Service.
 * @param path The path of the property to set
 * @param value The value to be set
 * @return Returns the full `config` object
 *
 * @example
 * ```js
 * // Set properties via dot notation
 * setConfigProperty(customerConfig, 'active_modules.search_match.enabled', true);
 * ```
 */
export function setConfigProperty(customerConfig, path, value) {
  _set(customerConfig, path, value);
  return customerConfig;
}

/**
 * Checks whether the specified module has been enabled in the customer
 * config.
 * @param customerConfig the config from customer service
 * @param module module to be checked
 * @return `boolean` indicating whether the module is enabled
 */
export function isModuleEnabled(customerConfig, module) {
  if (
    module === MODULES.CRM_INTEGRATION &&
    getConfigProperty(customerConfig, 'crm.is_enabled') !== true
  ) {
    return false;
  }
  const maybeActiveModulesArray = getConfigProperty(
    customerConfig,
    'active_modules'
  );
  if (
    Array.isArray(maybeActiveModulesArray) &&
    maybeActiveModulesArray.length > 0
  ) {
    return maybeActiveModulesArray.includes(module);
  }
  return (
    getConfigProperty(customerConfig, `active_modules.${module}.enabled`) ===
    true
  );
}

export function getCernerConfig(customerConfig) {
  return (
    getConfigProperty(customerConfig, 'third_party.cerner_healthelife') ?? {}
  );
}

export function isCernerIntegrationEnabled(customerConfig) {
  const maybeAccessControlListArray = getConfigProperty(
    customerConfig,
    'third_party.cerner_healthelife.access_control_list'
  );
  return (
    Array.isArray(maybeAccessControlListArray) &&
    maybeAccessControlListArray.length > 0
  );
}

export function isSearchAlertsEnabled(customerConfig) {
  return getConfigProperty(customerConfig, 'search_alerts.is_enabled') === true;
}

export function isDarkshipUseListPageSearchV9(customerConfig) {
  return (
    getConfigProperty(customerConfig, 'darkship_use_list_page_searchv9') ===
    true
  );
}

export function isDarkshipUseProfileSearchV9(customerConfig) {
  return (
    getConfigProperty(customerConfig, 'darkship_use_profile_searchv9') === true
  );
}

export function shouldRenderDirectBookInDrawer(customerConfig) {
  return (
    getConfigProperty(customerConfig, 'direct_book.render_type') === 'drawer' ||
    customerConfig?.feature_flags?.dbw_render_in_drawer === true
  );
}

export function getProductName(customerConfig = {}) {
  const persona =
    getConfigProperty(customerConfig, 'persona') ?? PERSONAS.CONSUMER;
  if (persona === PERSONAS.CONSUMER) {
    return PRODUCT_NAME_CONSUMER;
  }
  if (Object.values(PERSONAS).some((p) => persona === p)) {
    return persona;
  }
  return 'UNKNOWN';
}

export const defaultConfig = deepFreeze({
  active_modules: {
    search_match: {
      enabled: true
    },
    profile: {
      enabled: true
    }
  },
  application_string_templates: {},
  crm: undefined,
  display_availability_in_search: false,
  enable_read_only_availability: false,
  darkship_display_providers_on_locations_page: false,
  darkship_feature_flags: {
    updated_filter_bar: true,
    provider_tile_heads_up_availability: true,
    use_direct_book_v2: true
  },
  darkship_KENG_31725: false,
  darkship_use_profile_searchv9: false,
  darkship_use_list_page_searchv9: false,
  datadog: {
    rum_enabled: DATADOG.RUM_ENABLED,
    rum_session_sample_rate: DATADOG.RUM_SESSION_SAMPLE_RATE,
    rum_session_replay_sample_rate: DATADOG.RUM_SESSION_REPLAY_SAMPLE_RATE
  },
  domains: [],
  emergency_disclaimer: { enabled: false },
  emergency_disclaimer_md: { enabled: false },
  facets: [],
  facets_v9: [],
  feature_flags: {
    global_availability_controls: true,
    provider_pcp_booking_use_default_appt_purpose: true,
    provider_specialty_booking_use_default_appt_purpose: false,
    search_heads_up_availability: true,
    use_provider_profile_heads_up_availability: true
  },
  gxv2_enabled: false,
  hide_availability_density: false,
  index: undefined,
  insurance_disclaimer: { enabled: false },
  insurance_information_link_href: null,
  KENG_38276_show_search_suggestions_v9: false,
  locale: DEFAULT_LOCALE,
  location_facet: null,
  logos: [],
  logos_v9: [],
  minimum_appointment_purposes_for_filter: null,
  modal_display: {
    hide_cta: false,
    skip_modal: true,
    virtual_care: false,
    show_book_online_only_with_availability: false
  },
  persona: PERSONAS.CONSUMER,
  provider: {},
  provider_v9: {},
  provider_tile: undefined,
  show_powered_by_kyruus_logo: true,
  search_alerts: undefined,
  search_home: '/',
  search_params: undefined,
  search_results_page: {},
  search_results_page_v9: {},
  search_widget: {
    external_link: '',
    focus_on_widget: true,
    legal_message: 'off',
    location_search_radius: '25',
    persist_params: [],
    persist_query: false,
    show_filters: false,
    show_location: false,
    show_powered_by: false,
    sort_order: 'relevance',
    timeout: 4000,
    typeahead_categories: [
      { category: 'primary_care' },
      { category: 'clinical_experience' },
      { category: 'specialty_synonym' },
      { category: 'practice_group' },
      { category: 'name' }
    ],
    use_primary_care_condition: false,
    enable_pre_search_filters: false,
    enable_auto_search_on_select_suggestion: true
  },
  show_dbw_appt_time_alt_providers: false,
  show_provider_profile_alt_providers: false,
  skip_db_enablement_check: false,
  social_share: {},
  sort_options: undefined,
  tealium: {
    enabled: false,
    account: 'kyruus-hs',
    profile: 'main',
    // will probably want to pull this from the environment at some point
    environment: 'dev'
  },
  third_party: {}
});

const defaultConfigNonConsumer = deepFreeze({
  ...defaultConfig,
  active_modules: {
    search_match: {
      enabled: true
    },
    profile: {
      enabled: false
    }
  }
});
