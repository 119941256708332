import { type PMCConfig } from '@kyruus/types';
import { type Environment } from 'Common/types/environments';

type Deployment = {
  environment: Environment;
  stackVersion: string;
};

export interface InitialState {
  deployment?: Deployment;
  config?: PMCConfig;
}

export function getInitialStateFromPage(): InitialState {
  if (window?.document.getElementById('state')) {
    try {
      const state = window.document
        .getElementById('state')
        ?.getAttribute('content');
      if (!state) {
        throw new Error('Document state does not exist');
      }
      return JSON.parse(state);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error parsing state from page', e);
      return {};
    }
  }

  return {};
}
