import axios from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import _isEmpty from 'lodash/isEmpty';
import jwt_decode from 'jwt-decode';
import {
  ACCEPTED_DEEP_LINKING_PARAMS_MAP,
  COOKIE_DEFAULT_OPTIONS,
  COOKIE_EXPIRES_IN_HALF_HOUR,
  drawerCloseEventReason,
  EHR_AUTH_RESPONSE_TYPE,
  EHR_AUTH_SCOPE,
  EHR_CERNER,
  EHR_CRM,
  EHR_MYCHART_EMBEDDED,
  EHR_NONE,
  trackingReason
} from './constants';
import {
  CLINICAL_EXPERIENCE,
  CLINICAL_KEYWORD,
  CLINICAL_KEYWORDS
} from '../utils/constants';
import { isModuleEnabled, MODULES } from 'Common/config';
import { logSentryError } from '../utils/logSentryError';

export const getThirdPartySupported = (config) => {
  const { third_party = {}, cerner_db_route_to } = config;

  // generic CRM
  if (isModuleEnabled(config, MODULES.CRM_INTEGRATION)) {
    return {
      type: EHR_CRM
    };
  }

  // mychart (non-embedded) config
  try {
    const mychartLoginConfig = getMychartLoginConfig(third_party);
    if (!_isEmpty(mychartLoginConfig)) return mychartLoginConfig;
  } catch (e) {
    // Invalid login config
    return { type: EHR_NONE };
  }

  if (third_party?.mychart_embedded) {
    return { type: EHR_MYCHART_EMBEDDED };
  }

  // cerner
  if (cerner_db_route_to) {
    const ehrRegularConfig = getEhrRegularConfig(third_party);
    const featureFlags = {
      show_related: false,
      show_dropdown: false
    };
    if (!_isEmpty(ehrRegularConfig)) {
      featureFlags.show_related = ehrRegularConfig.show_related || false;
      featureFlags.show_dropdown = ehrRegularConfig.show_dropdown || false;
    }
    return {
      type: EHR_CERNER,
      featureFlags
    };
  }

  return { type: EHR_NONE };
};

/**
 * Parses the current URL and returns deepLinkingParams to pass to the DB widget.
 * @param {string} query the current URL's querystring
 * @returns {object} deepLinkingParams (shape matches the widget's deepLinkingParams propType)
 */
export const getDeepLinkingParams = (query) => {
  const searchParams = new URLSearchParams(query);
  const params = {};
  for (const [key, value] of searchParams) {
    if (ACCEPTED_DEEP_LINKING_PARAMS_MAP[key]) {
      params[ACCEPTED_DEEP_LINKING_PARAMS_MAP[key]] = value;
    }
  }
  return params;
};

/**
 * Parses the current URL for a searched clinical keyword.
 *   - Use case: GX pre-booking validation, specifically clinical validation.
 *   - Unified search is not supported, just typeahead search.
 * @param  {{search: string}} location - react-router location
 * @returns {{category: string, clinicalKeyword: string}|{}} the clinical keyword searched and its typeahead search category
 */
export const getClinicalKeywordSearched = (location = { search: '' }) => {
  const searchParams = new URLSearchParams(location.search);
  const categories = [CLINICAL_EXPERIENCE, CLINICAL_KEYWORDS];
  for (const category of categories) {
    const clinicalKeyword = searchParams.get(category);
    if (clinicalKeyword) return { category, clinicalKeyword };
  }
  return {};
};

/**
 * Parses the current URL and returns a GX context for pre-booking validation.
 *   - Use case: GX context is sent in request body to GX API
 * @param  {{search: string}} location - react-router location
 * @returns {{context: {clinical_keyword: string}|{}}} GX context the clinical keyword searched and its typeahead search category
 */
export const getGxContextualInfo = (location = { search: '' }) => {
  const { clinicalKeyword } = getClinicalKeywordSearched(location);
  const gxContextualInfo = {
    context: clinicalKeyword ? { [CLINICAL_KEYWORD]: clinicalKeyword } : {}
  };
  return gxContextualInfo;
};

/**
 * Builds a URL/path string with the given search params.
 * @param {string} baseURL the base url/path
 * @param {object} searchParams the url params stored as key value pairs
 * @returns {string|null} the final url/path
 */
export const createURLWithSearchParams = (baseURL, params = {}) => {
  if (!baseURL) return null;
  if (Object.keys(params).length === 0) return baseURL;
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    searchParams.append(key, params[key]);
  });
  // NOTE: replacing '+' with '%20' as URLSearchParams converts ' ' as '+'
  return `${baseURL}?${searchParams}`.replace(/\+/g, '%20');
};

/**
 * Returns the first non-login third party config
 * Currently, there can only be one cerner config per customer,
 * so this works.
 */
export const getEhrRegularConfig = (thirdPartyConfig) => {
  const third_party_keys = Object.keys(thirdPartyConfig);
  const targetThirdParty = third_party_keys.find(
    (key) => !thirdPartyConfig[key].db_login
  );
  if (targetThirdParty == null) return false;
  return thirdPartyConfig[targetThirdParty];
};

/**
 * Returns an EHR login config based on a given third-party customer config object.
 * @param {Object} thirdPartyConfig the third-party customer config object
 * @returns the EHR login config
 */
export const getMychartLoginConfig = (thirdPartyConfig) => {
  if (!thirdPartyConfig) return null;
  const thirdPartyLoginConfig = getThirdPartyLoginConfig(thirdPartyConfig);
  if (!thirdPartyLoginConfig) return null;
  const { type, featureFlags, loginConfig } = thirdPartyLoginConfig;
  const {
    db_login: {
      common_url_params: commonUrlParams = {},
      login_url_base: baseLoginUrl, // don't set default b/c conditional below
      login_url_params: loginUrlParams, // don't set default b/c conditional below
      show_proxy_booking_controls: showProxyBookingControls = false
    },
    issuer_to_source_system: issuerToSourceSystem = {}
  } = loginConfig;
  if (baseLoginUrl == null || loginUrlParams == null) return null;
  // Generate a one-time-use code (nonce) to validate on redirect.
  // The nonce is passed via the state parameter per the standard Oauth 2.0 flow.
  // See RFC6749: https://datatracker.ietf.org/doc/html/rfc6749#section-10.12
  // Or for simpler explanation: https://auth0.com/docs/configure/attack-protection/state-parameters
  const nonce = uuidv4();
  let loginUrl;
  try {
    loginUrl = createURLWithSearchParams(baseLoginUrl, {
      ...commonUrlParams,
      ...loginUrlParams,
      state: nonce,
      response_type: EHR_AUTH_RESPONSE_TYPE,
      scope: EHR_AUTH_SCOPE
    });
  } catch (e) {
    throw new Error(`Third-party login URL (${baseLoginUrl}) is invalid!`);
  }

  const getDeepLinkingUrl = (baseDeepLinkingUrl, deepLinkingUrlParams) => {
    if (!baseDeepLinkingUrl || !deepLinkingUrlParams) {
      throw new Error(
        "Missing required 'baseDeepLinkingUrl' or 'deepLinkingUrlParams'!"
      );
    }
    let deepLinkingUrl;
    try {
      deepLinkingUrl = createURLWithSearchParams(baseDeepLinkingUrl, {
        purpose: deepLinkingUrlParams.purpose,
        relationship: deepLinkingUrlParams.patientRel,
        skipGX: true,
        ...(deepLinkingUrlParams.selectedAppointment
          ? {
              period_start:
                deepLinkingUrlParams.selectedAppointment.start_datetime,
              location_id: deepLinkingUrlParams.selectedAppointment.location_id
            }
          : {})
      });
    } catch (e) {
      throw new Error(
        `Direct Book deep-linking URL (${baseDeepLinkingUrl}) is invalid!`
      );
    }

    // Deep-linking URL is stored in a short-lived cookie.
    // The nonce (sent in the auth request as the state parameter) is verified on redirect
    // and used to retrieve the deep-linking URL from the cookie.
    Cookies.set(nonce, deepLinkingUrl, {
      ...COOKIE_DEFAULT_OPTIONS,
      expires: COOKIE_EXPIRES_IN_HALF_HOUR
    });
    return deepLinkingUrl;
  };

  return {
    type,
    featureFlags,
    loginConfig: {
      loginUrl,
      getDeepLinkingUrl,
      commonUrlParams,
      loginUrlParams,
      showProxyBookingControls,
      issuerToSourceSystem
    }
  };
};

/**
 * Returns the first third party integration with login enabled.
 * @param {object} thirdPartyConfig object containing third party configuration
 * @return {object|false} If enabled, returns the third party login config. Otherwise, returns false.
 */
export const getThirdPartyLoginConfig = (thirdPartyConfig = {}) => {
  const third_party_keys = Object.keys(thirdPartyConfig);
  const targetThirdParty = third_party_keys.find(
    (key) =>
      thirdPartyConfig[key].db_login &&
      thirdPartyConfig[key].db_login.enabled === true
  );
  if (targetThirdParty == null) return false;
  const config = thirdPartyConfig[targetThirdParty];
  return {
    type: targetThirdParty,
    featureFlags: {
      show_related: config.show_related || false,
      show_dropdown: config.show_dropdown || false
    },
    loginConfig: thirdPartyConfig[targetThirdParty]
  };
};

/**
 * Method to create a callback for getting the EHR Patient Demographics
 * @returns
 */
export const createEhrPatientDemographicCallback = (
  log,
  customerCode,
  logMessages = {}
) => {
  const defaultLogMessages = {
    success: 'application_status.lookup_patient.success',
    failure: 'application_status.lookup_patient.failure'
  };
  const demographicLogMessages = {
    ...defaultLogMessages,
    ...logMessages
  };
  return async (ehr) => {
    try {
      const body = {
        patient_id: {
          ...ehr
        }
      };
      const patientsApiPath = `/api/scheduling/${encodeURIComponent(
        customerCode
      )}/patients`;

      const res = await axios.post(`${patientsApiPath}/lookup`, body);
      log(demographicLogMessages.success, {
        ...ehr
      });
      return res.data.result;
    } catch (e) {
      log(demographicLogMessages.failure, { ...ehr, error_message: e.message });
      throw e;
    }
  };
};

/**
 * Method to create a callback for getting the EHR Patient Demographics
 * @returns
 */
export const createEhrPatientProxiesCallback = (
  log,
  customerCode,
  logMessages = {}
) => {
  const defaultLogMessages = {
    success: 'application_status.lookup_patient_proxy.success',
    failure: 'application_status.lookup_patient_proxy.failure'
  };
  const demographicLogMessages = {
    ...defaultLogMessages,
    ...logMessages
  };
  return async (ehr) => {
    try {
      const body = {
        patient_id: {
          ...ehr
        }
      };
      const patientsApiPath = `/api/scheduling/${encodeURIComponent(
        customerCode
      )}/patients`;

      const res = await axios.post(`${patientsApiPath}/proxy_lookup`, body);
      log(demographicLogMessages.success, {
        ...ehr
      });
      return res.data.result;
    } catch (e) {
      log(demographicLogMessages.failure, { ...ehr, error_message: e.message });
      throw e;
    }
  };
};

/**
 * Function to parse and return patient demographic data from crm connector
 * @returns
 */
export const getCrmPatientDemographicData = (patient) => {
  if (!patient) {
    throw new Error('A patient object was not provided for demographic data');
  }

  return {
    dob: patient.dateOfBirth,
    email: patient.email,
    gender: patient.gender,
    name: {
      first: patient.firstName,
      last: patient.lastName,
      middle: patient.middleName
    },
    patientIds: [
      {
        pid: patient.mrn,
        pid_type: 'patient_id'
      }
    ],
    phones: [
      {
        number: patient.phoneNumber
      }
    ],
    address: {
      street1: patient.address.street1,
      street2: patient.address.street2,
      city: patient.address.city,
      state: patient.address.state,
      zip: patient.address.zip
    },
    /*
    Our SalesForce and Dynamics solutions do not send ssn to Kyruus.
    Customers can send ssn if they use their own patient lookup solution
    or if it's added manually through the patient reg form.
    */
    ssn: patient.ssn
  };
};

/**
 * Returns the sourceSystem (used to make the /patients/lookup call)
 * @param {string} accessToken JWT or opaque token
 * @param {Object} issuerToSourceSystem object from Customer Service config containing a mapping of JWT issuers to sourceSystems
 * @param {string} type third party supported type (i.e. "mychart")
 * @return {string}
 */
export const getSourceSystem = (
  accessToken = '',
  issuerToSourceSystem = {},
  type
) => {
  let decodedJwt = null;
  let jwtIssuer = null;

  try {
    try {
      decodedJwt = jwt_decode(accessToken);
    } catch (_) {
      // Access token is not a JWT, but that's OK
    }

    if (decodedJwt) {
      if (decodedJwt.iss) {
        jwtIssuer = decodedJwt.iss;
        if (jwtIssuer in issuerToSourceSystem) {
          return issuerToSourceSystem[jwtIssuer];
        } else {
          throw new Error(
            'Access token is a JWT, but its issuer is not in issuerToSourceSystem mapping'
          );
        }
      } else {
        throw new Error('Access token is a JWT, but its missing an issuer');
      }
    } else {
      /** If a non-empty accessToken is passed in, the accessToken may be an "opaque token".
       * To support some Epic customers' use of opaque tokens (vs JWTs),
       * when there is only one mapping in the issuer_to_source_system mapping from Customer Service config
       * we'll use that sourceSystem.
       */
      if (accessToken != '' && Object.keys(issuerToSourceSystem).length === 1) {
        return Object.values(issuerToSourceSystem)[0];
      } else {
        throw new Error(
          'Access token is not a JWT, and we have ambiguous source systems'
        );
      }
    }
  } catch (error) {
    logSentryError(`EHR auth source_system missing: ${error.message}`, {
      type,
      jwtIssuer,
      issuerToSourceSystem
    });
    return null;
  }
};

/**
 * Util to handle the scroll-to-top action in DB. DB defaults to window.scrollTo,
 * but in CCF, since the parent window scrolls, we need to call their SDK function
 * to scroll the parent to the top
 */
export const handleScrollToTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);

    try {
      // if we're in CCF, need to scroll the widget into view too since the parent window scrolls
      if (window?.$HL?.App?.scrollIntoView) {
        window.$HL.App.scrollIntoView();
      }
    } catch (e) {
      // nothing to do here
    }
  }
};

export const getReasonForDirectBookDrawerClosure = (reason) => {
  if (!reason) {
    return trackingReason.CLOSE_BUTTON;
  }
  if (reason === drawerCloseEventReason.ESCAPE_KEY_DOWN) {
    return trackingReason.ESCAPE_BUTTON;
  }
  if (reason === drawerCloseEventReason.BACKDROP_CLICK) {
    return trackingReason.BACKDROP_CLICK;
  }
};
