import { isGoogleAnalyticsLoaded, trackGaEvent } from 'Src/tracking';
import {
  AnalyticsProviderReport,
  AnalyticsProvider
} from './analytics-provider';

export class GoogleAnalyticsProvider implements AnalyticsProvider {
  public providerName = 'google';

  public sendEvent(
    eventName: string,
    _eventData: any
  ): AnalyticsProviderReport | null {
    if (!isGoogleAnalyticsLoaded()) {
      return null;
    }

    trackGaEvent(eventName);

    return {
      eventName
    };
  }
}
