import { events } from './constants';
import { getAllPersistedTokenValues } from './persisted-tokens';

/**
 * Pulls google analytics utm params from the url.
 * It's just pulling based on the param starting with `utm_`
 * @returns {Object} object in format of
 * ```
 * // all params will be prefixed with `utm_param__`
 * {
 *   utm_param__source: 'foo'
 * }
 * ```
 */
export const getUtmParams = () => {
  try {
    const params = getAllPersistedTokenValues();

    const utmParams = {};

    for (const [key, val] of params) {
      if (key.indexOf('utm_') === 0) {
        utmParams[`utm_param__${key}`] = val;
      }
    }

    if (Object.keys(utmParams).length === 0) {
      utmParams.utm_param = 'UNKNOWN';
    }

    return utmParams;
  } catch (e) {
    return {};
  }
};

/**
 * Collect 'rwg' (Reserve with google -- https://www.google.com/maps/reserve?ihs=4) params that google sends over
 * @param {*} location
 * @returns
 */
export const getRwgParams = (location = window.location) => {
  try {
    const params = new URLSearchParams(location.search);
    const rwgParams = {};
    for (const [key, val] of params) {
      if (key.indexOf('rwg_') === 0) {
        rwgParams[`rwg_param__${key}`] = val;
      }
    }
    return rwgParams;
  } catch (e) {
    return {};
  }
};

export const getExternalTrackingParams = (location = window.location) => {
  return {
    ...getUtmParams(),
    ...getRwgParams(location)
  };
};

/**
 * @param {String} key
 * @return {String}
 */
export const pageViewEvent = (key) => `${events.USER_ACTION_PAGE_VIEW}.${key}`;

/**
 * Returns a user event if the given `event name` should have specific tracking
 * @param {String} eventName
 * @param {Object} GAevent
 */
const parseUserEvent = (eventName) => {
  const ev = {
    DBOOK_LANDING: `${events.USER_ACTION_PAGE_VIEW}.directbook_landing`,
    APPOINTMENT_REASON: `${events.USER_ACTION_PAGE_VIEW}.appointment_reason`,
    PROVIDER_SCHEDULE: `${events.USER_ACTION_PAGE_VIEW}.provider_schedule`,
    PATIENT_REGISTRATION: `${events.USER_ACTION_PAGE_VIEW}.patient_registration`,
    CONFIRM_APPOINTMENT: `${events.USER_ACTION_PAGE_VIEW}.confirm_appointment`,
    BOOKED_APPOINTMENT: `${events.USER_ACTION_PAGE_VIEW}.booked_appointment`,
    EPIC_OSW: `${events.USER_ACTION_PAGE_VIEW}.epic_osw`
  };

  const steps = {
    [ev.EPIC_OSW]: { directBookStep: '1' },
    [ev.DBOOK_LANDING]: { directBookStep: '1' },
    [ev.APPOINTMENT_REASON]: { directBookStep: '2' },
    [ev.PROVIDER_SCHEDULE]: { directBookStep: '3' },
    [ev.PATIENT_REGISTRATION]: { directBookStep: '4' },
    [ev.CONFIRM_APPOINTMENT]: { directBookStep: '5' },
    [ev.BOOKED_APPOINTMENT]: { directBookStep: '6' }
  };

  for (const key in steps) {
    if (key === eventName) {
      return {
        ...steps[key],
        event: 'directbook',
        directBookLabel: eventName
      };
    }
  }

  return null;
};

/**
 * Returns a conversion event if the given `event name` should have a specific tracking
 * @param {String} eventName
 * @param {Object} GAevent
 */
const parseConversionEvent = (eventName) => {
  const ev = {
    BOOKING_SUCCESS: `${events.USER_ACTION}.pmcdb.booking.success`
  };

  const conversionEvents = {
    [ev.BOOKING_SUCCESS]: {}
  };

  for (const key in conversionEvents) {
    if (key === eventName) {
      return {
        ...conversionEvents[key],
        event: 'conversion',
        eventCategory: 'Conversions',
        eventAction: eventName
      };
    }
  }

  return null;
};

/**
 * If the given `eventName` is supposed to be tracked by GA in a specific way,
 * this will return the object to be used. e.g:
 *    event name is 'booking.success' we want to send to GA
 *    { conversion:'booking', eventCategory: 'Conversions' }
 *
 * @param {String} eventName
 * @param {Object} GAevent
 */
export const getGTMTrackingData = (eventName) => {
  const userEvent = parseUserEvent(eventName);
  if (userEvent) {
    return userEvent;
  }

  const conversionEvent = parseConversionEvent(eventName);
  if (conversionEvent) {
    return conversionEvent;
  }

  return null;
};

/**
 * Sends an event to each google analytics tracker that we have on the page
 * @param {String} eventName
 */
export const trackGaEvent = (eventName) => {
  if (isGoogleAnalyticsLoaded()) {
    window.ga(() => {
      const trackers = window.ga.getAll();
      trackers.forEach((tracker) => {
        if (eventName.indexOf(events.USER_ACTION_PAGE_VIEW) === -1) {
          tracker.send('event', 'user_action', eventName, eventName);
        }
      });
    });
  }

  const gtmTrackingData = getGTMTrackingData(eventName);
  if (window.dataLayer && gtmTrackingData) {
    window.dataLayer.push(gtmTrackingData);
  }
};

export const isGoogleAnalyticsLoaded = () => {
  return typeof window === 'object' && 'ga' in window;
};
