import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { type InitialState } from './state';

export const initDatadogRUM = ({
  initialState
}: {
  initialState: InitialState;
}) => {
  if (!initialState.config?.datadog?.rum_enabled) {
    return;
  }
  const datadogRumConfig: RumInitConfiguration = {
    applicationId: '5841edf1-c54d-4ca4-958f-2c6d894bd1d0',
    clientToken: 'pubb895f16552dddfd9066608bded192aad',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'providermatch-consumer',
    env: initialState.deployment?.environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: initialState.deployment?.stackVersion,
    sessionSampleRate: initialState.config?.datadog?.rum_session_sample_rate,
    sessionReplaySampleRate:
      initialState.config?.datadog?.rum_session_replay_sample_rate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [window.location.origin],
    trackViewsManually: true
  };
  datadogRum.init(datadogRumConfig);
};
