import { AnalyticsSummary } from 'Src/analytics';
import Cookies from 'js-cookie';

export function sendAdminAnalyticsReport(
  eventName: string,
  report: AnalyticsSummary
) {
  if (!window._admin_logging) {
    window._admin_logging = [];
  }

  const trackingSummaryObject = {
    eventName,
    time: Date.now(),
    ...report
  };

  window._admin_logging.push(trackingSummaryObject);
  window.dispatchEvent(
    new CustomEvent('admin_tracking_debug_call', {
      detail: trackingSummaryObject
    })
  );
}

export function isHammerheadEnvironment() {
  return window && window['%hammerhead%'];
}

export function shouldDebugAnalyticsProviders() {
  return Boolean(Cookies.get('debug_analytics'));
}

export function isAdminEnabled() {
  return window.admin && window.admin?.isEditorActive();
}
