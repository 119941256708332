// return the given url without a trailing slash
export function withoutTrailingSlash(url = '') {
  return url.replace(/\/+$/, '');
}

export function isCancelFlow() {
  if (typeof window !== 'undefined') {
    return window.location.pathname.includes('/cancel/');
  }
  return false;
}

export function isManageAppointmentFlow() {
  if (typeof window !== 'undefined') {
    return window.location.pathname.includes('/manage-appt/');
  }
  return false;
}
