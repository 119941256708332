import {
  AnalyticsSummary,
  AnalyticsProvider
} from './providers/analytics-provider';

/**
 * The AnalyticsService class is responsible for managing and sending analytics events
 * to multiple analytics providers. It supports adding providers, sending events, and
 * optionally logging debug information.
 */
export class AnalyticsService {
  public debug = false;

  private providers: AnalyticsProvider[];

  constructor() {
    this.providers = [];
  }

  /**
   * Adds an analytics provider to the service.
   * @param provider - The analytics provider to add.
   */
  public addProvider(provider: AnalyticsProvider): void {
    this.providers.push(provider);
  }

  /**
   * Sends an analytics event to all registered providers.
   * @param eventName - The name of the event to send.
   * @param eventData - The data associated with the event.
   * @returns An object containing the reports from each provider.
   */
  public sendEvent(eventName: string, eventData: any): AnalyticsSummary {
    const reports: AnalyticsSummary = {};

    for (const provider of this.providers) {
      try {
        const report = provider.sendEvent(eventName, eventData);

        if (report) {
          reports[provider.providerName] = report;
        } else {
          reports[provider.providerName] = 'event skipped';
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(
          `AnalyticsProvider ${provider.providerName} failed to send event.`,
          e
        );
      }
    }

    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log(
        JSON.stringify(
          {
            eventName,
            date: new Date().toISOString(),
            type: 'tracking',
            ...reports
          },
          // make it look ~nice~
          null,
          '\t'
        )
      );
    }

    return reports;
  }
}

/**
 * The default singleton instance of the AnalyticsService.
 */
export const analyticsService = new AnalyticsService();
